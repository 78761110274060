import React from "react";
import { useCustomer } from "../../../customHooks/useCustomer";
import './CustomerMenu.module.scss';
import classes from "./CustomerMenu.module.scss.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faBars, faReply } from "@fortawesome/free-solid-svg-icons";
import { useStyleContext } from "../../../customHooks/useStyleContext";

const CustomerMenu = ({ hideLink = false, hideReturn = false }) => {
    const { isLog, customer, setOpenModalLogin } = useCustomer();
    const styleContext = useStyleContext();
    const { company_name } = styleContext;
    const links = styleContext?.header?.links || [];
    const menuCollapseRef = React.useRef(null);
    const customerCollapseRef = React.useRef(null);
    const customerCollapseExtandedRef = React.useRef(null);

    const textColorIsDefined = styleContext?.header?.configs?.['config-text_color'] ?? null;
    const headerColorIsDefined = styleContext?.header?.configs?.['config-background_color'] ?? null;
    const linkColor = textColorIsDefined ? "" : "c-link";
    // NOTE: if textColorIsDefined is true, use it to insert inside style attribute
    const linkClass = linkColor + " pointer medium m-0";

    const getUrlFromName = (name, url = '') => {
        switch (name) {
            case 'header-links-booking':
                return url && url != '/reservation' ? url : '/reservation';
            case 'header-links-vehicles':
                return url && url != '/location-voitures' ? url : '/location-voitures';
            case 'header-links-contact':
                return url && url != '/contact' ? url : '/contact';
            default:
                return url && url != '/' ? url : '/';
        }
    }

    const bookingConfig = {};

    bookingConfig.exist = links.find((a) => a.name == 'header-links-booking');
    bookingConfig.name = bookingConfig.exist && bookingConfig.exist.label ? (bookingConfig.exist.label && bookingConfig.exist.label == 'Ma réservation' ? (isLog ? "Mes réservations" : 'Ma réservation') : bookingConfig.exist.label) : 'Mes réservations';
    bookingConfig.link = bookingConfig.exist ? getUrlFromName(bookingConfig.exist.name, bookingConfig.exist.url) : '/reservation';

    const filteredLinks = (hideBooking = true) => [...links]
        ?.sort((a, b) => a.display_order - b.display_order)
        ?.filter((a) => a.label != '' && (hideBooking ? a.name != 'header-links-booking' : true));

    // FEATURE: add smooth close (.collapse > .collapse .show > .collapsing > .collapse)
    React.useEffect(() => {
        const handleClick = (e) => {
            if (menuCollapseRef.current && !menuCollapseRef.current.contains(e.target) && menuCollapseRef.current.classList.contains('show')) {
                menuCollapseRef.current.classList.remove('show');
            }

            if (customerCollapseRef.current && !customerCollapseRef.current.contains(e.target) && customerCollapseRef.current.classList.contains('show')) {
                customerCollapseRef.current.classList.remove('show');
            }

            if (customerCollapseExtandedRef.current && !customerCollapseExtandedRef.current.contains(e.target) && customerCollapseExtandedRef.current.classList.contains('show')) {
                customerCollapseExtandedRef.current.classList.remove('show');
            }
        };

        window.addEventListener('click', handleClick);

        return () => window.removeEventListener('click', handleClick);
    }, []);

    if (isLog) {
        return (
            <div className={classes['safe-area']}>
                {!hideReturn && (window.location.pathname.indexOf('check-in') != -1) && (
                    <ReturnToHomePage />
                )}
                <div className={"d-none d-md-flex " + classes["customer-menu-container"]} style={{ marginLeft: 'auto' }}>
                    {!hideLink && filteredLinks()?.map((link, index) => (
                        <div
                            key={index}
                            className={"px-2 d-flex align-items-center medium fs14"}
                            onClick={() => window.location.href = getUrlFromName(link.name, link.url)}
                        >
                            <span
                                className={linkClass}
                                {...textColorIsDefined && { style: { color: textColorIsDefined } }}
                            >
                                {link.label}
                            </span>
                        </div>
                    ))}

                    <div id={classes["login-state"]} type="button" data-bs-toggle="collapse" data-bs-target={"#collapse-customer-menu"} aria-expanded="false" aria-controls={"collapse-customer-menu"}>
                        <div id={classes["paraf"]}>
                            <span>{customer.first_name.toUpperCase().substr(0, 1)}. {customer.last_name.toUpperCase().substr(0, 1)}</span>
                        </div>
                        <span
                            className={linkClass}
                            {...textColorIsDefined && { style: { color: textColorIsDefined } }}
                        >
                            {customer.first_name}
                        </span>
                        <div className={classes["fake-btn"]}>
                            <FontAwesomeIcon icon={faAngleDown} color={textColorIsDefined || "var(--c-link)"} />
                        </div>
                    </div>
                    <div {...headerColorIsDefined && { style: { backgroundColor: headerColorIsDefined } }} ref={customerCollapseExtandedRef} className={"collapse mt-1 " + classes["collapseCustomerMenu"]} id={"collapse-customer-menu"}>
                        {!hideLink && (
                            <a href={bookingConfig.link}>
                                <div className={classes["collapse-item"]}>
                                    <span
                                        className={linkClass}
                                        {...textColorIsDefined && { style: { color: textColorIsDefined } }}
                                    >{(bookingConfig.name === "Ma réservation" || bookingConfig.name === null) ? "Mes réservations" : bookingConfig.name}</span>
                                </div>
                            </a>
                        )}
                        <a href='/utilisateur/modifier'>
                            <div className={classes["collapse-item"]}>
                                <span
                                    className={linkClass}
                                    {...textColorIsDefined && { style: { color: textColorIsDefined } }}
                                >Éditer mon profil</span>
                            </div>
                        </a>
                        <a href='/utilisateur/deconnexion'>
                            <div className={classes["collapse-item"]}>
                                <span
                                    className={linkClass}
                                    {...textColorIsDefined && { style: { color: textColorIsDefined } }}
                                >Déconnexion</span>
                            </div>
                        </a>
                    </div>
                </div>

                <div className={"d-md-none d-flex " + classes["customer-menu-container"]} style={{ marginLeft: 'auto' }}>
                    <div id={classes["login-state"]} type="button" data-bs-toggle="collapse" data-bs-target={"#collapse-customer-menu"} aria-expanded="false" aria-controls={"collapse-customer-menu"}>
                        <div id={classes["paraf"]}>
                            <span>{customer.first_name.toUpperCase().substr(0, 1)}. {customer.last_name.toUpperCase().substr(0, 1)}</span>
                        </div>
                        <span
                            className={linkClass}
                            {...textColorIsDefined && { style: { color: textColorIsDefined } }}
                        >
                            {customer.first_name}
                        </span>
                        <div className={classes["fake-btn"]}>
                            <FontAwesomeIcon icon={faAngleDown} color={textColorIsDefined || "var(--c-link)"} />
                        </div>
                    </div>
                    <div {...headerColorIsDefined && { style: { backgroundColor: headerColorIsDefined } }} ref={customerCollapseRef} className={"collapse mt-1 " + classes["collapseCustomerMenu"]} id={"collapse-customer-menu"}>
                        <a href='/utilisateur/modifier'>
                            <div className={classes["collapse-item"]}>
                                <span
                                    className={linkClass}
                                    {...textColorIsDefined && { style: { color: textColorIsDefined } }}
                                >Éditer mon profil</span>
                            </div>
                        </a>
                        <a href='/utilisateur/deconnexion'>
                            <div className={classes["collapse-item"]}>
                                <span
                                    className={linkClass}
                                    {...textColorIsDefined && { style: { color: textColorIsDefined } }}
                                >Déconnexion</span>
                            </div>
                        </a>
                    </div>

                    {!hideLink && (
                        <>
                            <div className={"d-flex d-md-none " + classes["customer-menu-container"]}>
                                <button className="btn" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse-menu"} aria-expanded="false" aria-controls={"collapse-menu"}>
                                    <FontAwesomeIcon icon={faBars} color="var(--c-main)" />
                                </button>
                            </div>

                            <div {...headerColorIsDefined && { style: { backgroundColor: headerColorIsDefined } }} ref={menuCollapseRef} className={"collapse mt-1 " + classes["collapseCustomerMenu"]} id="collapse-menu">
                                <div className={classes["collapse-item"]}>
                                    <span
                                        className={linkClass}
                                        {...textColorIsDefined && { style: { color: textColorIsDefined } }}
                                        onClick={() => window.location.href = bookingConfig.link}
                                    >
                                        {bookingConfig.name}
                                    </span>
                                </div>
                                {filteredLinks()?.map((link, index) => (
                                    <div
                                        key={index}
                                        className={classes["collapse-item"]}
                                        onClick={() => window.location.href = getUrlFromName(link.name, link.url)}
                                    >
                                        <span
                                            className={linkClass}
                                            {...textColorIsDefined && { style: { color: textColorIsDefined } }}
                                        >
                                            {link.label}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className={classes['safe-area']}>
            {!hideReturn && (window.location.pathname.indexOf('check-in') != -1) && (
                <ReturnToHomePage companyName={company_name} />
            )}

            <div className={"d-none d-md-flex " + classes["customer-menu-container"]} style={{ marginLeft: 'auto' }}>
                {!hideLink && filteredLinks()?.map((link, index) => (
                    <div className={"px-3 d-flex align-items-center medium fs14"} key={index}>
                        <span
                            className={linkClass}
                            {...textColorIsDefined && { style: { color: textColorIsDefined } }}
                            onClick={() => window.location.href = getUrlFromName(link.name, link.url)}
                        >
                            {link.label}
                        </span>
                    </div>
                ))}
                {!hideLink && (
                    <div className="px-3 d-flex align-items-center medium fs14">
                        <span
                            className={linkClass}
                            {...textColorIsDefined && { style: { color: textColorIsDefined } }}
                            onClick={() => window.location.href = bookingConfig.link}
                        >
                            {bookingConfig.name}
                        </span>
                    </div>
                )}
            </div>

            <div id={classes["login-state"]}>
                <span
                    className={linkClass + ' mx-3'}
                    {...textColorIsDefined && { style: { color: textColorIsDefined } }}
                    onClick={() => setOpenModalLogin(true)}
                >Se connecter</span>
            </div>

            {!hideLink && (
                <>
                    <div className={"d-flex d-md-none " + classes["customer-menu-container"]}>
                        <button className="btn" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse-menu"} aria-expanded="false" aria-controls={"collapse-menu"}>
                            <FontAwesomeIcon icon={faBars} color="var(--c-main)" />
                        </button>
                    </div>

                    <div {...headerColorIsDefined && { style: { backgroundColor: headerColorIsDefined } }} ref={menuCollapseRef} className={"collapse mt-1 " + classes["collapseCustomerMenu"]} id={"collapse-menu"}>
                        <div className={classes["collapse-item"]}>
                            <span
                                className={linkClass}
                                {...textColorIsDefined && { style: { color: textColorIsDefined } }}
                                onClick={() => window.location.href = bookingConfig.link}
                            >
                                {bookingConfig.name}
                            </span>
                        </div>
                        {filteredLinks()?.map((link, index) => (
                            <div className={classes["collapse-item"]} key={index}>
                                <span
                                    className={linkClass}
                                    {...textColorIsDefined && { style: { color: textColorIsDefined } }}
                                    onClick={() => window.location.href = getUrlFromName(link.name, link.url)}
                                >
                                    {link.label}
                                </span>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

const ReturnToHomePage = () => {
    // note: shared component dont use useLocation
    const { pathname } = window.location;

    if (pathname === "/") {
        return null;
    }

    return (
        <div className={classes["return-home-button"]} onClick={() => {
            const location = window.location;

            if (location.pathname === "/reservation/confirmation" || location.pathname === "/reservation/paiement") {
                (async () => await fetch('/api/booking/clear'))();
            }

            return window.location.href = '/';
        }} title={"Retour à l'accueil"}>
            <FontAwesomeIcon icon={faReply} />
        </div>
    );
}

export default CustomerMenu;
export { ReturnToHomePage };